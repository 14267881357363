import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before",
        "parentName": "h1"
      }}><svg {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16",
          "parentName": "a"
        }}><path {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z",
            "parentName": "svg"
          }} /></svg></a>
      {`Introduction`}</h1>
    <p><a {...{
        "href": "https://beampipe.io",
        "parentName": "p"
      }}>{`Beampipe`}</a>
      {` is a web analytics tool that aims to be easy, powerful and privacy-conscious.`}</p>
    <p><img {...{
        "src": "/screenshot.png",
        "alt": "Screenshot",
        "parentName": "p"
      }} /></p>
    <p>{`Here are a few of the features we offer right out of the box:`}</p>
    <ul>

      <li {...{
        "parentName": "ul"
      }}>{`Understand your website's traffic and audience.`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Discover most visited pages, top traffic sources and geographic regions.`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Log custom events like sign-ups, logins or purchases.`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Use goals to better understand your user acquisition funnels.`}</li>


      <li {...{
        "parentName": "ul"
      }}><strong {...{
          "parentName": "li"
        }}>{`PRO`}</strong>
        {` Integrate with Slack to be notified when specific events occur or receive daily summary reports.`}</li>

    </ul>
    <p><a {...{
        "href": "/getting-started",
        "parentName": "p"
      }}>{`Get started now!`}</a></p>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      